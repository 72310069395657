.founders-note-wrapper{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: center;
}

.founders-note-container{
    background: #F8F8FF;
    border: 1px solid #FFFFFF;
    box-shadow: 4px 12px 32px rgb(41 44 79 / 10%);
    border-radius: 18px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 3rem;
    margin: 2rem 0;
}

.founders-note-title{
    font-family: 'AvenirNext-Bold';
    font-size: 1.5rem;
}

.founders-note-text{
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 1.2rem;
}

@media(max-width: 599px){
    .founders-note-text{
        font-size: 1.1rem;
    }
    .founders-note-container{
        padding: 1rem;
        margin: 0.5rem 0;
    }
    .founders-note-wrapper{
        padding: 1rem 0;
    }
    
}