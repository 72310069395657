.btn-fluid {
    max-width: 100%;
    height: 95px;
    margin: 0 40px 0 0;
}

@media only screen 
  and (min-width: 280px) 
  and (max-width: 319px)
{
    .list-inline-item{
        display: inline-block;
    }
    .btn-fluid {
        max-width: 46%;
        height: 2.6rem;
        margin: 0 10px 0 0;
    }
}

@media only screen 
  and (min-width: 320px) 
  and (max-width: 480px)
{
    .list-inline-item{
        display: inline-block;
    }
    .btn-fluid {
        max-width: 46%;
        height: 2.6rem;
        margin: 0 10px 0 0;
    }
}