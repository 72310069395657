.info-header {
    top: 0;
    width: 100%;
    cursor: pointer;
    background: #CECEFF;
    opacity: 1;
    position: fixed;
    z-index: 999;
    text-align: center;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
}

.info-header-text {
    margin-top: 0.8rem;
    margin-bottom: 0.7rem;
    font: normal normal bold 15px/18px AvenirNext-Bold;
    letter-spacing: 0px;
    color: #131313;
    text-align: start;
}

.info-header-action {
    text-decoration: underline;
}

.hiring-text{
    color: #5F5FC6;
    text-decoration: underline;
}