.content {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 130px;
}

.slider-wrapper {
  font-size: 70px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 390px;
  white-space: nowrap;
  margin-top: -90px;
  color: #10105E;
}

.animated-text{
  color: #294962;
  position: absolute;
  top: 36%;
  left: 42.5%;
  transform: translate(-50%,-50%);
  padding: 0 20px;
  height: 80px;
  overflow: hidden;
}

.line{
  margin-left: 20px;
  font-size: 70px;
  line-height: 64px;
  white-space: nowrap;
  margin-bottom:21px;
}

.line-1{
  color: #A1A1FF;
}
.line-2{
  color: #7070D4;
  
}
.line-3{
  color: #4545B1;
  
}
.line-4{
  color: #10105E;

}

.line:first-child{
  
  animation: anim 7s forwards;
    animation-delay:1.5s;
}

@keyframes anim {
  0%{
    margin-top: -10px;
  }
  30%{
    margin-top: -74px;
  }
  70%{
    margin-top: -162px;
  }
  100%{
    margin-top: -242px;
  }
}

@media only screen 
  and (min-width: 280px) 
  and (max-width: 320px)
{
  .animated-text{
    top: 111%;
    left: 40%;
    margin-left: 16px;
    margin-top: -62px;
  }

  .slider-wrapper {
    font-size: 28px;
    margin-right: 165px;
    white-space: nowrap;
    margin-top: 7px;
  }
  
  .line{
    font-size: 28px;
    line-height: 64px;
  }

  .content {
    margin-bottom: 89px;
  }
}

@media only screen 
  and (min-width: 321px) 
  and (max-width: 424px)
{
  .animated-text{
    top: 111%;
    left: 36%;
    margin-left: 16px;
    margin-top: -62px;
  }

  .slider-wrapper {
    font-size: 28px;
    margin-right: 210px;
    white-space: nowrap;
    margin-top: 7px;
  }
  
  .line{
    font-size: 28px;
    line-height: 64px;
  }

  .content {
    margin-bottom: 89px;
  }
}

@media only screen 
and (min-width: 425px) 
and (max-width: 812px)
{
  .animated-text{
    top: 111%;
    left: 33%;
    margin-left: 16px;
    margin-top: -62px;
  }

  .slider-wrapper {
    font-size: 28px;
    margin-right: 250px;
    white-space: nowrap;
    margin-top: 7px;
  }
  
  .line{
    font-size: 28px;
    line-height: 64px;
  }

  .content {
    margin-bottom: 89px;
  }
}
