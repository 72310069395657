:root {
  --light: 5;
}
/* body {
  background: hsl(0, 0%, calc(var(--light) * 1%));
  font-family: 'AvenirNext-Bold'; */
/* } */
@media (prefers-color-scheme: dark) {
  :root {
    --light: 5;
  }
}
@media (prefers-color-scheme: light) {
  :root {
    --light: 95;
  }
}
.not-found h1 {
  margin: 0;
}
.not-found a {
  color: hsl(0, 0%, calc((100 - var(--light)) * 1%));
  font-weight: bold;
}

.glitchy-text {
  color: hsl(0, 0%, calc((100 - var(--light, 95)) * 1%));
  display: inline-block;
  position: relative;
}
.glitchy-text__char--readable {
  opacity: 0;
  position: absolute;
}
.glitchy-text__char {
  color: transparent;
  position: relative;
}
.glitchy-text__char:after {
  --txt: attr(data-char);
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  -webkit-animation-timing-function: steps(1);
          animation-timing-function: steps(1);
  -webkit-animation-iteration-count: var(--count);
          animation-iteration-count: var(--count);
  -webkit-animation-name: glitch-switch;
          animation-name: glitch-switch;
  color: hsl(0, 0%, calc((100 - var(--light, 95)) * 1%));
  transition: color 0.2s ease 0s;
  position: absolute;
  content: var(--txt);
  left: 0;
  top: 0;
}
.bear__shades {
  opacity: var(--bear-shades);
}
.bear__tear-stream {
  fill: hsla(200, 100%, 75%, var(--bear-tears, 0));
}
.bear__brows {
  stroke: hsla(0, 0%, 0%, var(--bear-brows, 0));
}
.bear__eye {
  fill: hsla(0, 0%, 0%, var(--bear-eyes, 0));
}
.bear-logo {
  grid-row: span 2;
  position: relative;
  max-width: 400px;
  -o-object-fit: contain;
     object-fit: contain;
  height: 6rem;
  width: 6rem;
}
.bear__eye--teary {
  fill: hsla(200, 100%, 75%, calc(var(--bear-tears, 0) - 0.5));
}
@media (min-width: 768px) {
  .bear-logo {
    height: 12rem;
    width: 12rem;
  }
}

.return-link{
    font-family: 'AvenirNext-Bold';
    color:rgb(146, 107, 238)
}

.return-link:hover ~ .bear-logo--tears {
  --bear-brows: 0;
  --bear-tears: 0;
}
.bear-logo--tears {
  --bear-shades: 0;
  --bear-brows: 1;
  --bear-tears: 0.75;
  --bear-eyes: 1;
}
.bear-logo--tears path,
.bear-logo--tears circle,
.bear-logo--tears ellipse {
  transition: fill 0.2s ease 0s, transform 0.2s ease 0s;
}
.return-link:hover ~ .bear-logo--tears .bear__eye {
  transform: scaleY(1);
  fill: hsla(0, 100%, 0%, var(--bear-eyes));
}
.bear-logo--tears .bear__eye {
  transform: scaleY(0.25);
  transform-origin: 97px 894px;
  fill: hsla(200, 100%, 75%, var(--bear-eyes));
}
.bear-logo--tears .bear__eye--right {
  transform-origin: 201px 894px;
}
/* Handle mousemove translation of characters */
/* Logo */
.bear-logo--tears {
  --scaleX: -50;
  --scaleY: -50;
}
/* 404 */
.four-oh-four__code span:nth-of-type(2) {
  --scaleX: 30;
  --scaleY: -20;
}
.four-oh-four__code span:nth-of-type(3) {
  --scaleX: -20;
  --scaleY: 50;
}
.four-oh-four__code span:nth-of-type(4) {
  --scaleX: -30;
  --scaleY: 40;
}
/* Not found */
.four-oh-four__code-message span:nth-of-type(2) {
  --scaleX: 20;
  --scaleY: 10;
}
.four-oh-four__code-message span:nth-of-type(3) {
  --scaleX: 5;
  --scaleY: -10;
}
.four-oh-four__code-message span:nth-of-type(4) {
  --scaleX: 40;
  --scaleY: -30;
}
.four-oh-four__code-message span:nth-of-type(6) {
  --scaleX: 50;
  --scaleY: 50;
}
.four-oh-four__code-message span:nth-of-type(7) {
  --scaleX: 0;
  --scaleY: -15;
}
.four-oh-four__code-message span:nth-of-type(8) {
  --scaleX: 35;
  --scaleY: 10;
}
.four-oh-four__code-message span:nth-of-type(9) {
  --scaleX: -40;
  --scaleY: 25;
}
.four-oh-four__code-message span:nth-of-type(10) {
  --scaleX: 50;
  --scaleY: -25;
}
.bear-logo,
.four-oh-four__code span:nth-of-type(2):after,
.four-oh-four__code span:nth-of-type(3):after,
.four-oh-four__code span:nth-of-type(4):after,
.four-oh-four__code-message span:nth-of-type(2):after,
.four-oh-four__code-message span:nth-of-type(3):after,
.four-oh-four__code-message span:nth-of-type(4):after,
.four-oh-four__code-message span:nth-of-type(6):after,
.four-oh-four__code-message span:nth-of-type(7):after,
.four-oh-four__code-message span:nth-of-type(8):after,
.four-oh-four__code-message span:nth-of-type(9):after,
.four-oh-four__code-message span:nth-of-type(10):after {
  --translateX: calc((var(--X)) * var(--scaleX) * 1px);
  --translateY: calc((var(--Y)) * var(--scaleY) * 1px);
  transform: translate(var(--translateX), var(--translateY));
}
@-webkit-keyframes glitch-switch {
  0% {
    content: var(--char-0);
  }
  10% {
    content: var(--char-1);
  }
  20% {
    content: var(--char-2);
  }
  30% {
    content: var(--char-3);
  }
  40% {
    content: var(--char-4);
  }
  50% {
    content: var(--char-5);
  }
  60% {
    content: var(--char-6);
  }
  70% {
    content: var(--char-7);
  }
  80% {
    content: var(--char-8);
  }
  90% {
    content: var(--char-9);
  }
  100% {
    content: var(--char-0);
  }
}
@keyframes glitch-switch {
  0% {
    content: var(--char-0);
  }
  10% {
    content: var(--char-1);
  }
  20% {
    content: var(--char-2);
  }
  30% {
    content: var(--char-3);
  }
  40% {
    content: var(--char-4);
  }
  50% {
    content: var(--char-5);
  }
  60% {
    content: var(--char-6);
  }
  70% {
    content: var(--char-7);
  }
  80% {
    content: var(--char-8);
  }
  90% {
    content: var(--char-9);
  }
  100% {
    content: var(--char-0);
  }
}
