.scanner-modal-desktop > .modal-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 768px;
  height: 262px;
}

.scanner-modal-desktop > .modal-dialog > .modal-content {
  padding: 1rem;
  left: 0;
  bottom: 0;
  border-radius: 9px;
}

.scanner-modal-content {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2rem;
}

.scanner-info-text-outer-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.scanner-info-text {
  font-family: "AvenirNext-Bold", sans-serif;
  font-size: 1.313rem;
  letter-spacing: 0rem;
  color: #000000;
  text-align: center;
}

.scanner-qr-code {
  height: 16rem;
  width: 16rem;
}

.scanner-modal-body {
  /* background-image: url("https://cdn.mindpeers.co/site-icons/phone_illustrat.svg"); */
  /* background-repeat: no-repeat; */
  /* background-attachment: inherit; */
  /* background-position: 23rem 5rem; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.scanner-modal-close-btn {
  cursor: pointer;
}

.custom-scanner-modal {
  width: 40rem;
  max-width: 53.188rem;
}

.app-store-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  column-gap: 2rem;
}

.app-icon {
  width: 10rem;
  height: 3rem;
}
