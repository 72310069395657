.meet-img {
    position: relative;
    width: 14rem;
    height: 13rem;
}

.image-div {
    display: flex;
    justify-content: center;
    width: 100%;
}

.ot-section-title h2 {
    font-size: auto;
    color: #294962;
    font-family: 'AvenirNext-Bold';
}

.ot-tooltip {
    display: flex;
    justify-content: flex-end;
}

.ot-box {
    box-shadow: 0px 3px 10px #F0F2FF;
    border-radius: 18px;
    height: 550px;
}

.content-i-div {
    display: flex;
}

.ot-img-i-box {
    display: flex;
    justify-content: center;
    width: 100%;
}

.title-profile {
    font-size: 33px;
    line-height: 30px;
    color: #294962;
    letter-spacing: 0px;
    text-transform: capitalize;
    font-family: 'AvenirNext-Bold', sans-serif;
}

#our-therapiest .therapist-slick .slick-slider .slick-prev,
#our-therapiest .therapist-slick .slick-slider .slick-next {
    opacity: 0;
}

#our-therapiest:hover .therapist-slick .slick-slider .slick-prev,
#our-therapiest:hover .therapist-slick .slick-slider .slick-next {
    opacity: 1;
}

.therapist-slick .slick-prev,
.therapist-slick .slick-next {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    transition: all 0.5s ease;
}

.therapist-slick .slick-prev {
    left: 20px;
    z-index: 999;
}

.therapist-slick .slick-next {
    right: 20px;
}

.therapist-slick .slick-list {
    overflow: visible;
}

.therapist-slick .slick-disabled {
    display: none !important;
}

.ot-gender {
    position: relative;
    font-size: 14px;
    color: #294962;
    font-family: 'AvenirNext-pro';
}

.ot-heading {
    position: relative;
    padding-top: 10%;
}

.ot-heading h4 {
    font-size: 28px;
    color: #294962;
    text-transform: uppercase;
    font-family: 'AvenirNext-pro';
}

p.ot-sub-heading {
    position: relative;
    font-size: 18px;
    color: #4A4A4A;
}

.ot-book {
    position: relative;
}

.ot-book a {
    /* display: inline-block; */
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    border: 2px solid #A1A1FF;
    border-radius: 4px;
    height: 59px;
    line-height: 56px;
    text-transform: uppercase;
    font-size: 20px;
    color: #000000;
    font-family: 'AvenirNext-Regular';
    text-decoration: none;
    transition: all .3s ease;
}

a.ot-book:hover {
    background-color: #A1A1FF;
}

a.view-all-therapist {
    width: 309px;
    height: 66px;
    background-color: #A1A1FF;
    color: #000;
    font-size: 20px;
    font-family: 'AvenirNext-Regular';
    text-transform: uppercase;
    line-height: 64px;
    display: inline-block;
    border-radius: 4px;
    text-decoration: none;
}